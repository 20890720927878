export class Company {
  id = 0;
  seatsUsed = 0;
  seats = 0;
  plan: 'month' | 'year' = 'year';
  expiredDate = '';
  constructor(input: any) {
    this.id = input.id;
    this.seats = input.seats;
    this.seatsUsed = input.seatsUsed;
    this.plan = input.plan;
    this.expiredDate = input.expiredDate;
  }
  isExpired(): boolean {
    return this.expiredDate ? new Date() > new Date(this.expiredDate) : false;

  }
}
