import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InputService } from './utils/input.service';
import { BearerInterceptorService } from './interceptors/bearer-interceptor.service';
import { ErrorInterceptorService } from './interceptors/error-interceptor.service';
import {
  UserService, SocketService, UsersService, RoleService,
  AppService, AuthService, StorageService, CompanyService,
  ActiveDirectoryService, PasswordService, BillingService, GroupeService
} from './services';
import { AuthGuard, NotAuthGuard } from './guard/auth-guard.service';
import { ImportExportFileService } from './utils/ImportExportFile.service';
import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { environment } from 'src/environments/environment';
import { CanAccessPlanProrationGuard } from './guard/can-access-plan-proration.service';
import { IsOwnerGuard } from './guard/is-owner.service.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatePickerAdapter } from './utils/ngbDatePicker.service';

const MODULES_WITH_PROVIDER = [
  { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  { provide: NgbDateParserFormatter, useClass: NgbDatePickerAdapter }
];

const MODULES = [
  NgxStripeModule.forRoot(environment.stripeKey),
];

const SERVICES = [
  StorageService,
  CompanyService,
  AuthGuard,
  NotAuthGuard,
  CanAccessPlanProrationGuard,
  IsOwnerGuard,
  BearerInterceptorService,
  ErrorInterceptorService,
  InputService,
  AuthService,
  UserService,
  ImportExportFileService,
  AppService,
  SocketService,
  ActiveDirectoryService,
  RoleService,
  PasswordService,
  UsersService,
  BillingService,
  GroupeService
];

@NgModule({
  imports: [...MODULES],
  providers: [...SERVICES]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...MODULES_WITH_PROVIDER,
      ],
    };
  }
}
