import { BasePassword } from './basePassword.model';

export class PreloadPassword extends BasePassword {
  login = '';
  password?: string;
  note?: string;
  expiredAfter?: string;

  constructor(input: any) {
    super(input);
    this.login = input.login;
    if (this.password) {
      this.password = input.password;
    }
    if (input.note) {
      this.note = input.note;
    }
    if (input.expiredAfter) {
      this.expiredAfter = input.expiredAfter;
    }
  }

  generatePassword() {
    let pattern = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!=+?#@-_%*';
    let generatedPassword = '';
    if (this.excludeLowercase) {
      pattern = pattern.replace('abcdefghijklmnopqrstuvwxyz', '');
    }
    if (this.excludeUppercase) {
      pattern = pattern.replace('ABCDEFGHIJKLMNOPQRSTUVWXYZ', '');
    }
    if (this.excludeNumber) {
      pattern = pattern.replace('0123456789', '');
    }
    if (this.excludeSpecialCharacters) {
      pattern = pattern.replace('!=+?#@-_%*', '');
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.length; i++) {
      generatedPassword = generatedPassword.concat(pattern.charAt(Math.floor(Math.random() * pattern.length)));
    }
    this.password = generatedPassword;
  }

  prepareFilterSearch(pagination, extraFilter?) {
    const filter: any = {};
    filter.linePerPage = pagination.limit;
    filter.page = pagination.offset;
    if (extraFilter && extraFilter.replace(/\s/g, '').length > 0) {
      filter.search = extraFilter;
    }
    if (pagination.sort !== '' && pagination.order.replace(/\s/g, '').length > 0) {
      filter.sortBy = pagination.sort;
      filter.direction = pagination.order;
    }
    if (this.url && this.url.replace(/\s/g, '').length > 0) {
      filter.url = this.url;
    }
    if (this.login && this.login.replace(/\s/g, '').length > 0) {
      filter.login = this.login;
    }
    if (this.note && this.note.replace(/\s/g, '').length > 0) {
      filter.note = this.note;
    }
    if (this.level) {
      filter.level = this.level;
    }
    if (this.shareable === true || this.shareable === false) {
      filter.shareable = this.shareable ? 1 : 0;
    }
    if (this.group.length > 0) {
      filter.group = this.group[0];
    }
    return filter;
  }

  preparePrint(yes: string, no: string, lvl: string[]) {
    // tslint:disable-next-line: max-line-length
    return [this.url, this.login, this.shareable ? yes : no, lvl[this.level - 1], this.group.length > 0 ? this.group.join(' ;') : '-', this.expiredAfter ? this.expiredAfter : '-', this.note ? this.note : '-', this.length ? this.length : '-', this.excludeLowercase ? yes : no, this.excludeUppercase ? yes : no, this.excludeNumber ? yes : no, this.excludeSpecialCharacters ? yes : no];
  }

  prepareFilterGroup() {
    const filter: any = {};
    if (this.url && this.url.replace(/\s/g, '').length > 0) {
      filter.url = this.url;
    }
    if (this.login && this.login.replace(/\s/g, '').length > 0) {
      filter.login = this.login;
    }
    if (this.note && this.note.replace(/\s/g, '').length > 0) {
      filter.note = this.note;
    }
    if (this.level) {
      filter.level = this.level;
    }
    if (this.shareable === true || this.shareable === false) {
      filter.shareable = this.shareable;
    }
    return filter;
  }

}

export interface PasswordGroup {
  id: number;
  url: string;
  login: string;
  note?: string;
  shareable: boolean;
  level: number;
  checked?: boolean;
  group: string[];
}
