import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SocketService } from './socket.service';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Role } from 'src/app/shared/models/role.model';

@Injectable()
export class RoleService {
  private baseApiUrl = environment.apiUrl + 'company/role';
  private echoInstance = null;
  private listRoles = [];
  private socketRole$: BehaviorSubject<Role[]> = new BehaviorSubject([]);
  constructor(private http: HttpClient, private socketService: SocketService) { }

  startSocket() {
    this.getAllRoles()
      .then((suc) => {
        if (suc.success === 1) {
          this.listRoles = suc.roles;
          this.socketRole$.next(this.listRoles);
        }
      })
      .catch(() => { })
      .finally(() => {
        this.echoInstance = this.socketService.getEchoInstance();
        this.echoInstance.private(`company.${this.socketService.companyId}`)
          .listen('.RoleUpdatedEvent', (event) => {
            const index = this.listRoles.findIndex((element) => element.id === event.role.id);
            if (index !== -1) {
              this.listRoles[index] = event.role;
              this.socketRole$.next(this.listRoles);
            }
          });
      });
  }

  getAllRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/all')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getRoles(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl, { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateRole(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  stopSocket() {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}`);
      this.listRoles = [];
      this.socketRole$.next(this.listRoles);
    }
  }

  subSocketRole(): Observable<any> {
    return this.socketRole$;
  }
}
