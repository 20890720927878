import { Role } from './role.model';

export class User {
  id?: number;
  firstName = '';
  lastName = '';
  email = '';
  role = new Role({});
  changeablePassword?: boolean;
  imageUrl?: string;
  group?: string[] = [];
  countryCode?: number;
  phone?: number;
  country?: string;
  activated?: string | number;
  lastAppLoginStatus?: number;
  lastAppLogin?: string;
  registered?: boolean;
  backupFile?: string;
  status?: number;
  backupDate?: number;
  lang?: string;
  showRelaseNote = false;
  a2fStatus = false;
  constructor(input: any) {
    if (input.id) {
      this.id = input.id;
    }
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.role = new Role(input.role ? input.role : {});
    this.email = input.email;
    if (input.changeablePassword) {
      this.changeablePassword = input.changeablePassword;
    }
    if (input.imageUrl) {
      this.imageUrl = input.imageUrl;
    }
    this.group = input.group ? input.group : [];
    if (input.countryCode) {
      this.countryCode = input.countryCode;
    }
    if (input.phone) {
      this.phone = input.phone;
    }
    if (input.country) {
      this.country = input.country;
    }
    if (input.activated) {
      this.activated = input.activated;
    }
    if (input.registered) {
      this.registered = input.registered;
    }
    if (input.backupFile) {
      this.backupFile = input.backupFile;
    }
    if (input.status) {
      this.status = input.status;
    }
    if (input.backupDate) {
      this.backupDate = input.backupDate;
    }
    if (input.lang) {
      this.lang = input.lang;
    }
    if (input.showRelaseNote) {
      this.showRelaseNote = input.showRelaseNote;
    }
    if (input.a2fStatus) {
      this.a2fStatus = input.a2fStatus;
    }
  }

  prepareFilterSearch(pagination, extraFilter?) {
    const filter: any = {};
    filter.linePerPage = pagination.limit;
    filter.page = pagination.offset;
    if (extraFilter && extraFilter.replace(/\s/g, '').length > 0) {
      filter.search = extraFilter;
    }
    if (pagination.sort !== '' && pagination.order.replace(/\s/g, '').length > 0) {
      filter.sortBy = pagination.sort;
      filter.direction = pagination.order;
    }
    if (this.firstName && this.firstName.replace(/\s/g, '').length > 0) {
      filter.firstName = this.firstName;
    }
    if (this.lastName && this.lastName.replace(/\s/g, '').length > 0) {
      filter.lastName = this.lastName;
    }
    if (this.email && this.email.replace(/\s/g, '').length > 0) {
      filter.email = this.email;
    }
    if (this.country && this.country.replace(/\s/g, '').length > 0) {
      filter.country = this.country;
    }
    if (this.phone) {
      filter.phone = this.phone;
    }
    if (this.activated) {
      filter.activated = +this.activated - 1;
    }
    if (this.lastAppLoginStatus) {
      filter.lastAppLoginStatus = +this.lastAppLoginStatus;
    }
    if (typeof this.registered === 'boolean') {
      filter.registered = this.registered ? 1 : 0;
    }
    if (this.group.length > 0) {
      filter.group = this.group[0];
    }
    if (this.role.name && this.role.name.replace(/\s/g, '').length > 0) {
      filter.role = this.role.name;
    }
    return filter;
  }

  preparePrint() {
    // tslint:disable-next-line: max-line-length
    return [this.role.name, this.email, this.firstName, this.lastName, (this.countryCode && this.phone) ? '(' + this.countryCode + ') ' + this.phone : '-', this.group.length > 0 ? this.group.join(' ;') : '-'];
  }

  preparePrintUsersOfGroup(group) {
    // tslint:disable-next-line: max-line-length
    return [this.email, this.firstName, this.lastName, (this.countryCode && this.phone) ? '(' + this.countryCode + ') ' + this.phone : '-', group];
  }

  updateProfile(input: any) {
    if (input.firstName) {
      this.firstName = input.firstName;
    }
    if (input.lastName) {
      this.lastName = input.lastName;
    }
    if (input.imageUrl) {
      this.imageUrl = input.imageUrl;
    }
    if (input.countryCode) {
      this.countryCode = input.countryCode;
    }
    if (input.phone) {
      this.phone = input.phone;
    }
  }
  getFullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  prepareFilterGroup() {
    const filter: any = {};
    if (this.firstName && this.firstName.replace(/\s/g, '').length > 0) {
      filter.firstName = this.firstName;
    }
    if (this.lastName && this.lastName.replace(/\s/g, '').length > 0) {
      filter.lastName = this.lastName;
    }
    if (this.email && this.email.replace(/\s/g, '').length > 0) {
      filter.email = this.email;
    }
    if (this.role.name && this.role.name.replace(/\s/g, '').length > 0) {
      filter.role = this.role.name;
    }
    return filter;
  }

}

export interface UserGroup {
  id: number;
  firstName: string;
  lastName: string;
  role: Role;
  email?: string;
  checked?: boolean;
  group: string[];
}
