import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/shared/models/user.model';
import { SocketService } from './socket.service';


@Injectable()
export class UserService {

  private baseApiUrl = environment.apiUrl + 'manager';
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  private forceLogoutUser$: Subject<any> = new Subject();
  private echoInstance = null;

  constructor(private http: HttpClient,
    private socketService: SocketService) {
  }

  startSocket(): void {
    this.echoInstance = this.socketService.getEchoInstance();
    this.echoInstance.private(`company.${this.socketService.companyId}.user.${this.socketService.userId}`)
      .listen('.CurrentUserUpdatedEvent', (event) => {
        if (event.user.role.level > 1) {
          this.forceLogoutUser$.next();
        } else {
          this.setCurrentUser(new User(event.user));
        }
      });
  }

  stopSocket(): void {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.user.${this.socketService.userId}`);
    }
  }

  setCurrentUser(user: User): void {
    this.currentUser$.next(user);
  }

  SubCurrentUser(): Observable<User> {
    return this.currentUser$;
  }

  getCurrentUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateCurrentUserProfilePhoto(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/image', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateCurrentUserProfile(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePassword(data, jwt?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/password', data, { headers: { Authorization: `Bearer ${jwt}` } })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendEmailVerifCode(password): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/verif', { password })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  changeOwner(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/change', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getQrCodeA2F(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/a2f')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  disableApplicationA2F(passwordManager): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/a2f/disable', { passwordManager })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  enableApplicationA2F(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/a2f', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  SubForceLogout(): Observable<any> {
    return this.forceLogoutUser$;
  }
}
