import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import Echo from 'laravel-echo';
import * as socket from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketService {

  private echo: Echo = null;
  public companyId = null;
  public userId = null;
  constructor(private storageService: StorageService) {

  }
  getEchoInstance(): Echo {
    if (!this.echo) {
      this.refreshSocket();
    }
    return this.echo;
  }
  refreshSocket(companyId = this.companyId, userId = this.userId): void {
    this.companyId = companyId;
    this.userId = userId;
    if (this.storageService.getToken()) {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        host: environment.socketUrl,
        client: socket,
        auth:
        {
          headers:
          {
            Authorization: 'Bearer ' + this.storageService.getToken()
          }
        }
      });
    }
  }
}
