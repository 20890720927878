import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  private storageLangKey = 'mycenaLang';
  private storageThemeKey = 'mycenaTheme';
  private storageJwtKey = 'mycenaToken';
  private userDisplayPreferences = 'mycenaDisplayPrefBusiness';

  public userPref: UserPref = {
    usersChart: true,
    seatsChart: true,
    passwordChart: true,
    backupChart: true,
    params: true,
    preloadPassword: true,
    preloadUrl: true,
    profile: true,
    uploadPreloadPassword: true,
    uploadPreloadUrl: true,
    uploadUsers: true,
    users: true,
    grcDownload: true,
    grcList: true,
    grcChart: true,
    proration: true,
    billingInfo: true,
    payment: true,
    invoice: true,
    a2f: true
  };

  getToken(): string {
    return sessionStorage.getItem(this.storageJwtKey);
  }

  setToken(token: string): void {
    sessionStorage.setItem(this.storageJwtKey, token);
  }

  clearToken(): void {
    sessionStorage.removeItem(this.storageJwtKey);
  }

  getLang(): string {
    return localStorage.getItem(this.storageLangKey);
  }

  setLang(lang): void {
    localStorage.setItem(this.storageLangKey, lang);
  }

  getTheme(): string {
    return localStorage.getItem(this.storageThemeKey);
  }

  setTheme(theme): void {
    localStorage.setItem(this.storageThemeKey, theme);
  }

  loadUserPref() {
    const prefUser = JSON.parse(localStorage.getItem(this.userDisplayPreferences));
    if (prefUser) {
      this.userPref = prefUser;
    }
  }

  getPref(key): boolean {
    return typeof (this.userPref[key]) === 'boolean' ? this.userPref[key] : true;
  }

  setPref(key: string, val: boolean): void {
    this.userPref[key] = val;
    localStorage.setItem(this.userDisplayPreferences, JSON.stringify(this.userPref));
  }

}

export interface UserPref {
  seatsChart: boolean;
  usersChart: boolean;
  passwordChart: boolean;
  backupChart: boolean;
  params: boolean;
  uploadPreloadPassword: boolean;
  preloadPassword: boolean;
  uploadPreloadUrl: boolean;
  preloadUrl: boolean;
  uploadUsers: boolean;
  users: boolean;
  profile: boolean;
  proration: boolean;
  billingInfo: boolean;
  payment: boolean;
  invoice: boolean;
  grcDownload: boolean;
  grcList: boolean;
  grcChart: boolean;
  a2f: boolean;
}
