export class BasePassword {
  id?: number;
  url = '';
  shareable = false;
  length?: number;
  excludeLowercase?: boolean;
  excludeUppercase?: boolean;
  excludeNumber?: boolean;
  excludeSpecialCharacters?: boolean;
  level = 1;
  group = [];
  image?: {
    img?: string;
    label?: string;
  };

  constructor(input: any) {
    if (input.id) {
      this.id = input.id;
    }
    this.url = input.url;
    this.shareable = input.shareable;
    this.setRules(input);
    this.level = input.level;
    this.group = input.group ? input.group : [];
    if (input.image) {
      this.image = input.image;
    }
  }

  setRules(input: any) {
    if (input.length) {
      this.length = input.length
    }
    if (typeof input.excludeLowercase === 'boolean') {
      this.excludeLowercase = input.excludeLowercase;
    }
    if (typeof input.excludeUppercase === 'boolean') {
      this.excludeUppercase = input.excludeUppercase
    }
    if (typeof input.excludeNumber === 'boolean') {
      this.excludeNumber = input.excludeNumber
    }
    if (typeof input.excludeSpecialCharacters === 'boolean') {
      this.excludeSpecialCharacters = input.excludeSpecialCharacters
    }
  }
}
