import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class BillingService {

  private baseApiUrl = environment.apiUrl + 'payment';

  constructor(private http: HttpClient) { }

  getBillingInformation(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/billing-info')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateBillingInformation(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/billing-info', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPaymentMethod(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/card')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePaymentMethod(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/card', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getInvoice(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/invoices')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPlans(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiUrl + 'proration')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePlan(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + 'proration', { userData: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  cancelSubscription(passwordManager: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/subscription/cancel', { passwordManager })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}

export interface IbillingInfo {
  companyName: string;
  address: string;
  indicative?: number;
  phone?: number;
}

export interface IcardInformation {
  cardBrand: string;
  cardExp: string;
  cardNumber: string;
}

export interface Iinvoice {
  number: string;
  date: string;
  paid: boolean;
  total: string;
  isTrial: boolean;
  file: string;
}

export interface Itiers {
  flatAmount: number;
  unitAmount: number;
  upTo: number;
}

export interface Iplan {
  id: string;
  interval: 'year' | 'month';
  currency: string;
  tiers: Itiers[];
}
