import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService, StorageService, UserService, AppService, AuthService, SocketService, RoleService } from './core/services';
import { Company } from './shared/models/company.model';
import { User } from './shared/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mccc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public loading = true;

  constructor(private translateService: TranslateService,
    private companyService: CompanyService,
    private authService: AuthService,
    private appService: AppService,
    private socketService: SocketService,
    private storageService: StorageService,
    private roleService: RoleService,
    private userService: UserService) {
  }

  ngOnInit(): void {
    this.storageService.loadUserPref();
    const localStorageTheme = this.storageService.getTheme();
    if (localStorageTheme && ['light', 'dark'].includes(localStorageTheme)) {
      this.appService.setCurrentActiveTheme(localStorageTheme);
    } else {
      this.appService.setCurrentActiveTheme('light');
    }
    this.translateService.addLangs(['en', 'es', 'fr', 'pt']);
    this.translateService.setDefaultLang(environment.defaultLang);
    const localStorageLang = this.storageService.getLang();
    const browserLang = this.translateService.getBrowserLang();
    if (localStorageLang && ['pt', 'fr', 'en', 'es'].includes(localStorageLang)) {
      this.translateService.use(localStorageLang);
    } else if (browserLang && ['pt', 'fr', 'en', 'es'].includes(browserLang)) {
      this.translateService.use(browserLang);
      this.storageService.setLang(browserLang);
    } else {
      this.translateService.use(environment.defaultLang);
      this.storageService.setLang(environment.defaultLang);
    }
    this.userService.SubCurrentUser().subscribe((user) => {
      if (user && user.lang && ['pt', 'fr', 'en', 'es'].includes(user.lang)) {
        this.translateService.use(user.lang);
        this.storageService.setLang(user.lang);
      }
    });

    // case when we have a token and the application refresh for some reason do we need to get our current user and company
    if (this.storageService.getToken()) {
      Promise.all([this.companyService.getCurrentCompany(),
      this.userService.getCurrentUser()])
        .then(([response1, response2]) => {
          if (response1.success === 1 && response2.success === 1) {
            const company = new Company(response1.Company);
            const user = new User(response2.user);
            this.companyService.setCurrentCompany(company);
            this.userService.setCurrentUser(user);
            this.socketService.refreshSocket(company.id, user.id);
            this.authService.startSocket();
            this.companyService.startSocket();
            this.userService.startSocket();
            this.roleService.startSocket();
          } else {
            this.authService.logout(true);
          }
        })
        .catch((err) => {
          if (err.status !== 401 && err.status !== 403 && err.status !== 0) {
            this.authService.logout(true);
          }
        }).finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }
}
