import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
  HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService, StorageService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,
    private router: Router) {
  }
  private errorInterceptorEnabled = true;
  // tslint:disable-next-line: max-line-length
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if (err.status === 401 || err.status === 403 || err.status === 0) {
        if (this.errorInterceptorEnabled) {
          this.errorInterceptorEnabled = false;
          if (this.router.url.includes('/auth/reset') || !this.router.url.includes('/auth')) {
            this.authService.logout(true);
          }
          setTimeout(() => {
            this.errorInterceptorEnabled = true;
          }, 3000);
        }
      }
      return throwError(err);
    }));
  }
}
