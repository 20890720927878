import { BasePassword } from './basePassword.model';

export class PreloadUrl extends BasePassword {
  reminderPassword?: number;

  constructor(input: any) {
    super(input);
    if (input.reminderPassword) {
      this.reminderPassword = input.reminderPassword;
    }
  }

  prepareFilterSearch(pagination, extraFilter?) {
    const filter: any = {};
    filter.linePerPage = pagination.limit;
    filter.page = pagination.offset;
    if (extraFilter && extraFilter.replace(/\s/g, '').length > 0) {
      filter.search = extraFilter;
    }
    if (pagination.sort !== '' && pagination.order.replace(/\s/g, '').length > 0) {
      filter.sortBy = pagination.sort;
      filter.direction = pagination.order;
    }
    if (this.url && this.url.replace(/\s/g, '').length > 0) {
      filter.url = this.url;
    }
    if (this.length) {
      filter.length = this.length;
    }
    if (this.excludeLowercase === true || this.excludeLowercase === false) {
      filter.excludeLowercase = this.excludeLowercase ? 1 : 0;
    }
    if (this.excludeUppercase === true || this.excludeUppercase === false) {
      filter.excludeUppercase = this.excludeUppercase ? 1 : 0;
    }
    if (this.excludeNumber === true || this.excludeNumber === false) {
      filter.excludeNumber = this.excludeNumber ? 1 : 0;
    }
    if (this.excludeSpecialCharacters === true || this.excludeSpecialCharacters === false) {
      filter.excludeSpecialCharacters = this.excludeSpecialCharacters ? 1 : 0;
    }
    if (this.level) {
      filter.level = this.level;
    }
    if (this.shareable === true || this.shareable === false) {
      filter.shareable = this.shareable ? 1 : 0;
    }
    if (this.reminderPassword) {
      filter.reminderPassword = this.reminderPassword;
    }
    if (this.group.length > 0) {
      filter.group = this.group[0];
    }
    return filter;
  }

  preparePrint(yes: string, no: string, lvl: string[]) {
    // tslint:disable-next-line: max-line-length
    return [this.url, this.length, this.excludeLowercase ? yes : no, this.excludeUppercase ? yes : no, this.excludeNumber ? yes : no, this.excludeSpecialCharacters ? yes : no, this.shareable ? yes : no, lvl[this.level - 1], this.group.length > 0 ? this.group.join(' ;') : '-', this.reminderPassword ? this.reminderPassword : '-'];
  }

  prepareFilterGroup() {
    const filter: any = {};
    if (this.url && this.url.replace(/\s/g, '').length > 0) {
      filter.url = this.url;
    }
    if (this.level) {
      filter.level = this.level;
    }
    if (this.shareable === true || this.shareable === false) {
      filter.shareable = this.shareable;
    }
    return filter;
  }
}


export interface UrlGroup {
  id: number;
  url: string;
  shareable: boolean;
  level: number;
  checked?: boolean;
  group: string[];
}
