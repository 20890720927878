export const environment = {
  production: false,
  stripeKey: 'pk_test_B2auZI4Epah6lmzUUU7r8d6j00QGelJK6Q',
  apiUrl: 'https://dev.apipro.mycena.co/',
  socketUrl: 'https://dev.socket.apipro.mycena.co',
  defaultLang: 'en',
  recaptchaKey: '6Le_veMUAAAAAPtECwl1XL65KopbaQt-46U5ayuG'
  // apiUrl: 'http://localhost:8888/public/',
  // socketUrl: 'http://localhost:6001',
};
