import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { Observable, from } from 'rxjs';
import * as jsPDF from 'jspdf';
import { UserOptions } from 'jspdf-autotable';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import { User } from 'src/app/shared/models/user.model';
import { InputService } from './input.service';
import { PreloadUrl } from 'src/app/shared/models/preloadUrl.model';
import { PreloadPassword } from 'src/app/shared/models/preloadPassword.model';
import { Role } from 'src/app/shared/models/role.model';

export interface ImportExport {
  code: impExpEnum;
  data: any[] | any;
  fails?: number;
  failsLvl?: boolean;
}

export enum impExpEnum {
  ok = 1,
  errorMultipleFile = 2,
  errorType = 3,
  errorFormatData = 4,
  errorImport = 5,
  errorEmpty = 6
}

@Injectable()
export class ImportExportFileService {

  constructor(private inputService: InputService) {
  }

  importUsersExcel(e: any, roleList: Role[], currentUser: User): Observable<ImportExport> {
    const allowedLvl = roleList.map((item) => item.level);
    const inputController = this.inputService.getInputConfig('user');
    let userNotImported = 0;
    return from(new Promise<ImportExport>((resolve) => {
      // test file exist and just 1 single file
      if (!e || !e.target || !e.target.files || e.target.files.length !== 1) {
        resolve({ code: impExpEnum.errorMultipleFile, data: null });
      }
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        // test extension
        // tslint:disable-next-line: max-line-length
        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' !== e.target.files[0].type && 'application/vnd.ms-excel' !== e.target.files[0].type) {
          return resolve({ code: impExpEnum.errorType, data: null });
        } else {
          let hex = '';
          new Uint8Array(event.target.result).slice(0, 4).forEach((byte) => {
            hex += byte.toString(16);
          });
          // test magic code
          if (hex.toUpperCase() !== 'D0CF11E0' && hex.toUpperCase() !== '504B34') {
            return resolve({ code: impExpEnum.errorType, data: null });
          } else {
            // parse data from file
            const wb: XLSX.WorkBook = XLSX.read(event.target.result, { type: 'buffer' });
            const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
            const parseData = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: undefined, }) as Array<any>;
            parseData.splice(0, 1);
            const dataParsed: User[] = [];
            for (const row of parseData) {
              if (row.length < 4 || row.length > 6 ||
                !row[0] || row[0].length > inputController.firstNameLengthMax || row[0].length < inputController.firstNameLengthMin ||
                !row[1] || row[1].length > inputController.lastNameLengthMax || row[1].length < inputController.lastNameLengthMin ||
                !row[2] || typeof row[2] !== 'number' || row[2] > 3 || row[2] < 1 || !allowedLvl.includes(row[2]) ||
                !row[3] || !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(row[3])) {
                userNotImported += 1;
              } else {
                const roleIndexNewUser = roleList.findIndex(item => item.level === row[2]);
                const newUser = new User({
                  firstName: row[0] ? String(row[0]) : '',
                  lastName: row[1] ? String(row[1]) : '',
                  email: row[3] ? String(row[3]) : '',
                  country: row[4] ? String(row[4]) : '',
                  // tslint:disable-next-line: max-line-length
                  group: row[5] ? row[5].split(';').map((element) => element.replace(/^\s+|\s+$|\s+(?=\s)/, '')).filter((item, index, self) => index === self.indexOf(item)) : []
                });
                if (roleIndexNewUser !== -1 && roleList[roleIndexNewUser].level > currentUser.role.level) {
                  newUser.role = roleList[roleIndexNewUser];
                }
                dataParsed.push(newUser);
              }
            }
            if (parseData.length === 0) {
              return resolve({ code: impExpEnum.errorEmpty, data: null });
            } else if (dataParsed.length === 0) {
              return resolve({ code: impExpEnum.errorFormatData, data: null });
            } else {
              return resolve({ code: impExpEnum.ok, data: dataParsed, fails: userNotImported });
            }
          }
        }
      };

      fileReader.onerror = (event: any) => {
        return resolve({ code: impExpEnum.errorImport, data: null });
      };
      fileReader.readAsArrayBuffer(e.target.files[0]);
    }));
  }

  importPreloadUrlExcel(e: any, companyAutorizedLvl: number): Observable<ImportExport> {
    const inputController = this.inputService.getInputConfig('url');
    let passwordNotImported = 0;
    let containBadLvl = false;
    return from(new Promise<ImportExport>((resolve) => {
      // test file exist and just 1 single file
      if (!e || !e.target || !e.target.files || e.target.files.length !== 1) {
        resolve({ code: impExpEnum.errorMultipleFile, data: null });
      }
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        // test extension
        // tslint:disable-next-line: max-line-length
        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' !== e.target.files[0].type && 'application/vnd.ms-excel' !== e.target.files[0].type) {
          return resolve({ code: impExpEnum.errorType, data: null });
        } else {
          let hex = '';
          new Uint8Array(event.target.result).slice(0, 4).forEach((byte) => {
            hex += byte.toString(16);
          });
          // test magic code
          if (hex.toUpperCase() !== 'D0CF11E0' && hex.toUpperCase() !== '504B34') {
            return resolve({ code: impExpEnum.errorType, data: null });
          } else {
            // parse data from file
            const wb: XLSX.WorkBook = XLSX.read(event.target.result, { type: 'buffer' });
            const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
            const parseData = (XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: undefined, })) as Array<any>;
            parseData.splice(0, 1);
            const dataParsed: PreloadUrl[] = [];
            for (const row of parseData) {
              if (row.length < 8 || row.length > 10 ||
                !row[0] || row[0].length > inputController.urlLengthMax || row[0].length < inputController.urlLengthMin ||
                // tslint:disable-next-line: max-line-length
                !row[1] || typeof row[1] !== 'number' || row[1] > inputController.passwordLengthMax || row[1] < inputController.passwordLengthMin ||
                !row[2] || !['y', 'n', 'o', 's'].includes(String(row[2]).toLowerCase()[0]) ||
                !row[3] || !['y', 'n', 'o', 's'].includes(String(row[3]).toLowerCase()[0]) ||
                !row[4] || !['y', 'n', 'o', 's'].includes(String(row[4]).toLowerCase()[0]) ||
                !row[5] || !['y', 'n', 'o', 's'].includes(String(row[5]).toLowerCase()[0]) ||
                !row[6] || !['y', 'n', 'o', 's'].includes(String(row[6]).toLowerCase()[0]) ||
                // tslint:disable-next-line: max-line-length
                !row[7] || !['bronze', 'bronce', 'silver', 'plata', 'prata', 'argent', 'gold', 'oro', 'or', 'ouro'].includes(String(row[7]).toLowerCase()) ||
                row[9] && row[9] !== '-' && typeof row[9] !== 'number' || row[9] > inputController.changePasswordFrequencyMax || row[9] < inputController.changePasswordFrequencyMin ||
                // tslint:disable-next-line: max-line-length
                (String(row[2]).toLowerCase() === 'y' && String(row[3]).toLowerCase() === 'y' && String(row[4]).toLowerCase() === 'y' && String(row[5]).toLowerCase() === 'y')) {
                passwordNotImported += 1;
              } else {
                const lowerlvl = String(row[7]).toLowerCase();
                const passwordLvl = (lowerlvl === 'bronze' || lowerlvl === 'bronce') ? 1 : (lowerlvl === 'silver' || lowerlvl === 'argent' || lowerlvl === 'plata' || lowerlvl === 'prata') ? 2 : 3;
                if (companyAutorizedLvl > passwordLvl) {
                  passwordNotImported += 1;
                  containBadLvl = true;
                } else {
                  const newUrl = new PreloadUrl({
                    url: String(row[0]),
                    length: Number(row[1]),
                    excludeLowercase: ['y', 's', 'o'].includes(String(row[2]).toLowerCase()[0]) ? true : false,
                    excludeUppercase: ['y', 's', 'o'].includes(String(row[3]).toLowerCase()[0]) ? true : false,
                    excludeNumber: ['y', 's', 'o'].includes(String(row[4]).toLowerCase()[0]) ? true : false,
                    excludeSpecialCharacters: ['y', 's', 'o'].includes(String(row[5]).toLowerCase()[0]) ? true : false,
                    shareable: ['y', 's', 'o'].includes(String(row[6]).toLowerCase()[0]) ? true : false,
                    // tslint:disable-next-line: max-line-length
                    level: passwordLvl,
                    // tslint:disable-next-line: max-line-length
                    group: (row[8] && row[8] !== '-') ? row[8].split(';').map((element) => element.replace(/^\s+|\s+$|\s+(?=\s)/, '')).filter((item, index, self) => index === self.indexOf(item)) : []
                  });
                  if (row[9] && row[9] !== '-') {
                    newUrl.reminderPassword = row[9];
                  }
                  dataParsed.push(newUrl);
                }
              }
            }
            if (parseData.length === 0) {
              return resolve({ code: impExpEnum.errorEmpty, data: null });
            } else if (dataParsed.length === 0) {
              return resolve({ code: impExpEnum.errorFormatData, data: null });
            } else {
              return resolve({ code: impExpEnum.ok, data: dataParsed, fails: passwordNotImported, failsLvl: containBadLvl });
            }
          }
        }
      };
      fileReader.onerror = (event: any) => {
        return resolve({ code: impExpEnum.errorImport, data: null });
      };
      fileReader.readAsArrayBuffer(e.target.files[0]);
    }));
  }

  importPreloadPasswordExcel(e: any, companyPasswordLength: number, companyAutorizedLvl: number): Observable<ImportExport> {
    const inputController = this.inputService.getInputConfig('password');
    let passwordNotImported = 0;
    let containBadLvl = false;
    return from(new Promise<ImportExport>((resolve) => {
      // test file exist and just 1 single file
      if (!e || !e.target || !e.target.files || e.target.files.length !== 1) {
        resolve({ code: impExpEnum.errorMultipleFile, data: null });
      }
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        // test extension
        // tslint:disable-next-line: max-line-length
        if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' !== e.target.files[0].type && 'application/vnd.ms-excel' !== e.target.files[0].type) {
          return resolve({ code: impExpEnum.errorType, data: null });
        } else {
          let hex = '';
          new Uint8Array(event.target.result).slice(0, 4).forEach((byte) => {
            hex += byte.toString(16);
          });
          // test magic code
          if (hex.toUpperCase() !== 'D0CF11E0' && hex.toUpperCase() !== '504B34') {
            return resolve({ code: impExpEnum.errorType, data: null });
          } else {
            // parse data from file
            const wb: XLSX.WorkBook = XLSX.read(event.target.result, { type: 'buffer' });
            const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
            const parseData = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: undefined, }) as Array<any>;
            parseData.splice(0, 1);
            const dataParsed: PreloadPassword[] = [];
            for (const row of parseData) {
              if (row.length < 5 || row.length > 13 ||
                !row[0] || row[0].length > inputController.urlLengthMax || row[0].length < inputController.urlLengthMin ||
                !row[1] || row[1].length > inputController.loginLengthMax || row[1].length < inputController.loginLengthMin ||
                !row[2] || !['y', 'n', 'o', 's'].includes(String(row[2]).toLowerCase()[0]) ||
                (row[5] && (row[5] !== '-' && (!/(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/.test(row[5]) || this.testDateIsNotCorrect(row[5])))) || // bad password expire after
                // tslint:disable-next-line: max-line-length
                !row[3] || !['bronze', 'bronce', 'silver', 'plata', 'prata', 'argent', 'gold', 'oro', 'or', 'ouro'].includes(String(row[3]).toLowerCase()) ||
                row[7] && String(row[7]).length > inputController.passwordLengthMax || String(row[7]).length < inputController.passwordLengthMin || //password exist with bad length
                !row[7] && (!row[8] || !row[9] || !row[10] || !row[11] || !row[12]) || // no password and miss password creteria
                row[8] && row[8] !== '-' && (typeof row[8] !== 'number' || row[8] > inputController.passwordLengthMax || row[8] < inputController.passwordLengthMin) || // bad length/type password length
                row[9] && !['y', 'n', 'o', 's'].includes(String(row[9]).toLowerCase()[0]) ||
                row[10] && !['y', 'n', 'o', 's'].includes(String(row[10]).toLowerCase()[0]) ||
                row[11] && !['y', 'n', 'o', 's'].includes(String(row[11]).toLowerCase()[0]) ||
                row[12] && !['y', 'n', 'o', 's'].includes(String(row[12]).toLowerCase()[0])
                // tslint:disable-next-line: max-line-length
              ) {
                passwordNotImported += 1;
              } else {
                const lowerlvl = String(row[3]).toLowerCase();
                const passwordLvl = (lowerlvl === 'bronze' || lowerlvl === 'bronce') ? 1 : (lowerlvl === 'silver' || lowerlvl === 'argent' || lowerlvl === 'plata' || lowerlvl === 'prata') ? 2 : 3;
                if (companyAutorizedLvl > passwordLvl) {
                  passwordNotImported += 1;
                  containBadLvl = true;
                } else {
                  const newPassword = new PreloadPassword({
                    url: String(row[0]),
                    login: String(row[1]),
                    shareable: ['y', 's', 'o'].includes(String(row[2]).toLowerCase()[0]) ? true : false,
                    // tslint:disable-next-line: max-line-length
                    level: passwordLvl,
                    // tslint:disable-next-line: max-line-length
                    group: (row[4] && row[4] !== '-') ? row[4].split(';').map((element) => element.replace(/^\s+|\s+$|\s+(?=\s)/, '')).filter((item, index, self) => index === self.indexOf(item)) : [],
                    note: (row[6] && row[6] !== '-') ? String(row[6]) : '',
                    length: (row[8] && row[8] !== '-') ? Number(row[8]) : companyPasswordLength,
                    excludeLowercase: ['y', 's', 'o'].includes(String(row[9]).toLowerCase()[0]) ? true : false,
                    excludeUppercase: ['y', 's', 'o'].includes(String(row[10]).toLowerCase()[0]) ? true : false,
                    excludeNumber: ['y', 's', 'o'].includes(String(row[11]).toLowerCase()[0]) ? true : false,
                    excludeSpecialCharacters: ['y', 's', 'o'].includes(String(row[12]).toLowerCase()[0]) ? true : false
                  });
                  if (row[7]) {
                    newPassword.password = String(row[7]);
                  } else {
                    newPassword.generatePassword();
                  }
                  if (row[5] && row[5] !== '-') {
                    newPassword.expiredAfter = String(row[5]);
                  }
                  dataParsed.push(newPassword);
                }
              }
            }
            if (parseData.length === 0) {
              return resolve({ code: impExpEnum.errorEmpty, data: null });
            } else if (dataParsed.length === 0) {
              return resolve({ code: impExpEnum.errorFormatData, data: null });
            } else {
              return resolve({ code: impExpEnum.ok, data: dataParsed, fails: passwordNotImported, failsLvl: containBadLvl });
            }
          }
        }
      };
      fileReader.onerror = (event: any) => {
        return resolve({ code: impExpEnum.errorImport, data: null });
      };
      fileReader.readAsArrayBuffer(e.target.files[0]);
    }));
  }

  importImage(e: any): Observable<ImportExport> {
    return from(new Promise<ImportExport>((resolve) => {
      // test file exist and just 1 single file
      if (!e || !e.target || !e.target.files || e.target.files.length !== 1) {
        resolve({ code: impExpEnum.errorMultipleFile, data: null });
      }
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        // test extension
        if (!(/image\/(png|jpg|jpeg)/.test(e.target.files[0].type.toLowerCase()))) {
          return resolve({ code: impExpEnum.errorType, data: null });
        } else {
          let hex = '';
          new Uint8Array(event.target.result).slice(0, 4).forEach((byte) => {
            hex += byte.toString(16);
          });
          // test magic code
          if (hex.toUpperCase() !== '89504E47' && !hex.toUpperCase().includes('FFD8FF')) {
            return resolve({ code: impExpEnum.errorType, data: null });
          } else {
            return resolve({ code: impExpEnum.ok, data: event.target.result });
          }
        }
      };
      fileReader.onerror = (event: any) => {
        return resolve({ code: impExpEnum.errorImport, data: null });
      };
      fileReader.readAsArrayBuffer(e.target.files[0]);
    }));
  }

  exportAsPdfFile(fileName, columns, body) {
    const doc = new jsPDF('l', 'pt', 'a2') as JsPDFWithPlugin;
    doc.autoTable({ columns, body, tableWidth: 'auto', headStyles: { cellWidth: 'auto' }, bodyStyles: { cellWidth: 'auto' } });
    doc.save(fileName);
  }

  exportAsXlsx(fileName, columns, body) {
    const doc = XLSX.write(
      { Sheets: { Data: XLSX.utils.sheet_add_aoa(XLSX.utils.aoa_to_sheet([columns]), body, { origin: -1 }) }, SheetNames: ['Data'] },
      { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }), fileName);
  }

  saveFile(data, fileName) {
    saveAs(data, fileName);
  }

  testDateIsNotCorrect(dateString: string): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const dateSplite = dateString.split('/');
    return (Math.ceil((new Date(dateSplite[1] + '/' + dateSplite[0] + '/' + dateSplite[2]).getTime() - currentDate.getTime()) / (1000 * 3600 * 24)) < 1)
  }

}

interface JsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}
