import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SocketService } from './socket.service';
import { PreloadPassword } from 'src/app/shared/models/preloadPassword.model';
import { PreloadUrl } from 'src/app/shared/models/preloadUrl.model';

@Injectable()
export class PasswordService {

  private baseApiUrl = environment.apiUrl + 'company';
  private echoInstance = null;
  private socketPreloadPassword$: Subject<IpreloadWithType> = new Subject();
  private socketPreloadUrl$: Subject<IpreloadWithType> = new Subject();
  private statusPreloadUrl = 0;
  private statusPreloadPassword = 0;

  constructor(private http: HttpClient,
    private socketService: SocketService) { }

  startPreloadUrlSocket() {
    this.statusPreloadUrl += 1;
    if (this.statusPreloadUrl === 1) {
      this.echoInstance = this.socketService.getEchoInstance();
      this.echoInstance.private(`company.${this.socketService.companyId}.preload-url`)
        .listen('.CompanyPreloadUrlAddedEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'add', password: event.preloadUrl });
        })
        .listen('.CompanyPreloadUrlListAddedEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'addPasswords', passwords: event.preloadUrls });
        })
        .listen('.CompanyPreloadUrlUpdatedEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'update', password: event.preloadUrls });
        })
        .listen('.CompanyPreloadUrlUpdatedForGroupEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'updateGroup', passwords: event.preloadUrls });
        })
        .listen('.CompanyPreloadUrlDeletedEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'delete', passwords: event.preloadUrls });
        })
        .listen('.CompanyPreloadUrlDeletedAllEvent', (event) => {
          this.socketPreloadUrl$.next({ action: 'deleteALL' });
        });
    }
  }

  startPreloadPasswordSocket() {
    this.statusPreloadPassword += 1;
    if (this.statusPreloadPassword === 1) {
      this.echoInstance = this.socketService.getEchoInstance();
      this.echoInstance.private(`company.${this.socketService.companyId}.preload-password`)
        .listen('.CompanyPreloadPasswordAddedEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'add', password: event.preloadPassword });
        })
        .listen('.CompanyPreloadPasswordListAddedEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'addPasswords', passwords: event.preloadPasswords });
        })
        .listen('.CompanyPreloadPasswordUpdatedEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'update', password: event.preloadPasswords });
        })
        .listen('.CompanyPreloadPasswordUpdatedForGroupEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'updateGroup', passwords: event.preloadPasswords });
        })
        .listen('.CompanyPreloadPasswordDeletedEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'delete', passwords: event.preloadPasswords });
        })
        .listen('.CompanyPreloadPasswordDeletedAllEvent', (event) => {
          this.socketPreloadPassword$.next({ action: 'deleteALL' });
        });
    }
  }

  subSocketPreloadUrl(): Observable<IpreloadWithType> {
    return this.socketPreloadUrl$;
  }

  subSocketPreloadPassword(): Observable<IpreloadWithType> {
    return this.socketPreloadPassword$;
  }

  stopPreloadUrlSocket() {
    this.statusPreloadUrl -= 1;
    if (this.echoInstance && this.statusPreloadUrl === 0) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.preload-url`);
    }
  }

  stopPreloadPasswordSocket() {
    this.statusPreloadPassword -= 1;
    if (this.echoInstance && this.statusPreloadPassword === 0) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.preload-password`);
    }
  }

  getPreloadPassword(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/preloadPassword', { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPreloadPassword(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/preloadPassword', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPreloadPasswords(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/preloadPassword/import', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePreloadPassword(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadPassword', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePreloadPassword(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadPassword', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePreloadPasswords(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadPasswords', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteAllPreloadPassword(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadPassword/all', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateImagePreloadPasswords(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadPassword/images', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPreloadUrl(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/preloadUrl', { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPreloadUrl(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/preloadUrl', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addPreloadUrls(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/preloadUrl/import', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePreloadUrl(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadUrl', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePreloadUrl(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadUrl', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deletePreloadUrls(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadUrls', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteAllPreloadUrl(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/preloadUrl/all', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateImagePreloadUrls(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadUrl/images', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  pingLogPasswordVisible(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/preloadPassword/visible', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateImagePreloadPassword(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadPassword/image', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateImagePreloadUrl(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/preloadUrl/image', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}


export interface IpreloadWithType {
  password?: PreloadPassword | PreloadUrl;
  passwords?: PreloadPassword[] | PreloadUrl[];
  action: 'add' | 'update' | 'delete' | 'addPasswords' | 'updateGroup' | 'deleteALL';
}
