import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class GroupeService {

  private baseApiUrl = environment.apiUrl + 'company/group/manage';
  private baseApiUrlDetails = environment.apiUrl + 'company/group/manage/details';

  constructor(private http: HttpClient) { }

  // get count entity for a group
  getGroups(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl, { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // add a new group
  addGroup(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // delete an existing group
  deleteGroup(id, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/' + id, { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  // update an existing group
  updateGroup(id, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/' + id, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // get all entity for a group
  getGroupDetails(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrlDetails + (id ? '/' + id : ''))
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getGroupDetailsUser(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrlDetails + '/' + (id ? id + '/' : '') + 'users')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getGroupDetailsPreloadPassword(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrlDetails + '/' + (id ? id + '/' : '') + 'preloadpassword')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getGroupDetailsPreloadUrl(id?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrlDetails + '/' + (id ? id + '/' : '') + 'preloadurl')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
