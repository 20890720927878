import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from '../services';

@Injectable()
export class IsOwnerGuard implements CanActivate {
  constructor(private router: Router,
    private userService: UserService) {

  }

  canActivate(): Observable<boolean> {
    return this.userService.SubCurrentUser()
      .pipe(first((user) => user !== null),
        map((user: User) => {
          if (!user) {
            this.router.navigate(['pages']);
            return false;
          } else {
            if (user.role.level !== 0) {
              this.router.navigate(['pages']);
              return false;
            } else {
              return true;
            }
          }
        })
      );
  }

}
