import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable()
export class ActiveDirectoryService {

  private baseApiUrl = environment.apiUrl + 'company/active-directory';

  constructor(private http: HttpClient) {
  }

  getAdSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addUpdateSettings(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/parameter', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteAdSettings(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/parameter', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  testAdConnection(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/test', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserListFromAD(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/user/import', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addUsers(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/user', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
