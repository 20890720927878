export class Role {
  id?: number;
  level: number;
  name = '';
  lightTheme: '#053158';
  darkTheme: '#FFFFFF';
  constructor(input: any) {
    if (input.id) {
      this.id = input.id as number;
    }
    this.level = input.level;
    this.name = input.name ? input.name : '';
    this.lightTheme = input.lightTheme ? input.lightTheme : '#053158';
    this.darkTheme = input.darkTheme ? input.darkTheme : '#FFFFFF';
  }
}
