import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SocketService } from './socket.service';
import { Subject, Observable } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';

@Injectable()
export class UsersService {

  private baseApiUrl = environment.apiUrl + 'company/user';
  private socketUsers$: Subject<IuserWithType> = new Subject();
  private echoInstance = null;

  constructor(private http: HttpClient,
    private socketService: SocketService) { }

  startUsersSocket() {
    this.echoInstance = this.socketService.getEchoInstance();
    this.echoInstance.private(`company.${this.socketService.companyId}.users`)
      .listen('.UserAddedEvent', (event) => {
        this.socketUsers$.next({ action: 'add', user: event.user });
      })
      .listen('.UsersCreateListEvent', (event) => {
        this.socketUsers$.next({ action: 'addUsers', users: event.users });
      })
      .listen('.UserUpdatedEvent', (event) => {
        this.socketUsers$.next({ action: 'update', user: event.user });
      })
      .listen('.UserUpdatedForGroupEvent', (event) => {
        this.socketUsers$.next({ action: 'updateGroup', users: event.users });
      })
      .listen('.UserChangedToManagerEvent', (event) => {
        this.socketUsers$.next({ action: 'changeManager', user: event.newManager });
      })
      .listen('.UserDeletedEvent', (event) => {
        this.socketUsers$.next({ action: 'delete', users: event.users });
      })
      .listen('.UserDeletedAllEvent', (event) => {
        this.socketUsers$.next({ action: 'deleteALL' });
      });
  }

  stopUsersSockets() {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.users`);
    }
  }

  subSocketUsers(): Observable<IuserWithType> {
    return this.socketUsers$;
  }

  getUsers(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl, { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addUsers(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 's', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addUser(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateUser(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl, data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteUser(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl, { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteAllUsers(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + '/all', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLastBackup(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/backups', { params: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  downLoadBackupFile(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/backup/download', data, { responseType: 'blob' })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  resetClientSecQuestions(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/reset/questions', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUserCanBeOwner(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/all')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteUsers(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.request('delete', this.baseApiUrl + 's', { body: data })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}


export interface IuserWithType {
  user?: User;
  users?: User[];
  action: 'add' | 'update' | 'delete' | 'deleteALL' | 'changeManager' | 'addUsers' | 'updateGroup';
}
