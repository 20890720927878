import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Company } from 'src/app/shared/models/company.model';
import { CompanyService } from '../services';

@Injectable()
export class CanAccessPlanProrationGuard implements CanActivate {
  constructor(private router: Router,
    private companyService: CompanyService) {

  }

  canActivate(): Observable<boolean> {
    return this.companyService.SubCurrentCompany()
      .pipe(first((company) => company !== null),
        map((company: Company) => {
          if (!company) {
            this.router.navigate(['pages']);
            return false;
          } else {
            if (!company.plan) {
              this.router.navigate(['pages']);
              return false;
            } else {
              return true;
            }
          }
        })
      );
  }

}
