import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable()
export class AppService {

  public currentTheme = 'light';
  private currentSideBarStatus$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private currentSideBarIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
  private currentActiveTheme$: BehaviorSubject<'light' | 'dark'> = new BehaviorSubject('light');

  constructor(private storageService: StorageService) { }

  setCurrentSideBarStatus(status: boolean): void {
    this.currentSideBarStatus$.next(status);
  }

  SubCurrentSideBarStatus(): Observable<boolean> {
    return this.currentSideBarStatus$;
  }

  setCurrentSideBarIndex(status: number): void {
    this.currentSideBarIndex$.next(status);
  }

  SubCurrentSideBarIndex(): Observable<number> {
    return this.currentSideBarIndex$;
  }

  setCurrentActiveTheme(theme) {
    this.currentTheme = theme;
    this.currentActiveTheme$.next(theme);
    this.storageService.setTheme(theme);
  }

  SubCurrentActiveTheme(): Observable<'light' | 'dark'> {
    return this.currentActiveTheme$;
  }
}

export interface MenuItem {
  key: string;
  class?: string;
  link?: string;
  children?: MenuItem[];
  checkShowBilling?: boolean;
}
