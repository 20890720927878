import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from './company.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { SocketService } from './socket.service';
import { RoleService } from './role.service';

@Injectable()
export class AuthService {

  private showLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private baseApiUrl = environment.apiUrl + 'manager/';
  private echoInstance = null;

  constructor(private http: HttpClient,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private userService: UserService,
    private zone: NgZone,
    private router: Router,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private socketService: SocketService,
    private roleService: RoleService,
    private storageService: StorageService) { }

  startSocket(): void {
    this.userService.SubForceLogout().subscribe(() => {
      this.logout(true);
    });
    this.echoInstance = this.socketService.getEchoInstance();
    this.echoInstance.private(`company.${this.socketService.companyId}.user.${this.socketService.userId}`)
      .listen('.CurrentUserDeletedEvent', () => { // DONE
        this.logout(true);
      })
      .listen('.CurrentUserDesactivatedEvent', () => { // DONE
        this.logout(true);
      });
  }

  stopSocket(): void {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.user.${this.socketService.userId}`);
    }
  }

  SetCurrentLoadingStatus(status: boolean): void {
    this.showLoading$.next(status);
  }

  SubCurrentLoadingStatus(): Observable<boolean> {
    return this.showLoading$;
  }

  verifCredentials(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'credentials', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  login(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'login', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  forgetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'password/email', { email })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  checkToken(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'password/reset/verif', { token })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'password/reset', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  reseta2f(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'a2f/reset', { email })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  validateReseta2f(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'a2f/validReset', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  logout(sessionExpired = false): void {
    this.modalService.dismissAll();
    this.http.get(this.baseApiUrl + 'logout')
      .toPromise()
      .then(() => { })
      .catch(() => { })
      .finally(() => {
        if (sessionExpired) {
          this.toastService.warning(this.translateService.instant('globalToast.sessionExpired'));
        }
        this.zone.run(() => {
          this.router.navigate(['auth']);
        });
        this.userService.setCurrentUser(null);
        this.companyService.setCurrentCompany(null);
        this.storageService.clearToken();
        this.stopSocket();
        this.userService.stopSocket();
        this.companyService.stopSocket();
        this.roleService.stopSocket();
      });
  }

  isLoggedIn(): boolean {
    if (this.storageService.getToken()) {
      return true;
    } else {
      return false;
    }
  }
}
