import { Injectable } from '@angular/core';
import { Company } from 'src/app/shared/models/company.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SocketService } from './socket.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CompanyService {

  private baseApiUrl = environment.apiUrl + 'company';
  private currentCompany$: BehaviorSubject<Company> = new BehaviorSubject(null);
  private echoInstance = null;
  private listGroup = [];
  private socketCharts$: Subject<IChartsUpdate> = new Subject();
  private socketParams$: Subject<IParamsUpdate> = new Subject();
  private socketListGroup$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private socketLog$: Subject<any> = new Subject();
  private timeout = null;

  constructor(private http: HttpClient,
    private modalService: NgbModal,
    private socketService: SocketService) { }

  startSocket() {
    this.getGroupList()
      .then((suc) => {
        if (suc.success === 1) {
          this.listGroup = suc.group;
          this.socketListGroup$.next(this.listGroup);
        }
      })
      .catch(() => { })
      .finally(() => {
        this.echoInstance = this.socketService.getEchoInstance();
        this.echoInstance.private(`company.${this.socketService.companyId}`)
          .listen('.SeatsUpdatedEvent', (event) => {
            this.setCurrentCompany(new Company(event.company));
          })
          .listen('.GroupAddedEvent', (event) => {
            this.listGroup = this.listGroup.concat(event.group);
            this.socketListGroup$.next(this.listGroup);
          })
          .listen('.GroupDeletedEvent', (event) => {
            this.listGroup = this.listGroup.filter((item) => !event.groups.includes(item));
            this.socketListGroup$.next(this.listGroup);
          })
          .listen('.CompanyUpdatedEvent', (event) => {
            this.setCurrentCompany(new Company(event.companyWeb));
          })
          .listen('.CompanyAddLogEvent', () => {
            this.socketLog$.next();
          });
      });
  }

  startChartsSocket() {
    this.echoInstance = this.socketService.getEchoInstance();
    this.echoInstance.private(`company.${this.socketService.companyId}.charts`)
      .listen('.CompanyTotalSeatsEvent', (event) => {
        this.socketCharts$.next({ key: 'seats', value: event.totalSeats });
      })
      .listen('.CompanyRegistredUsersEvent', (event) => {
        this.socketCharts$.next({ key: 'users', value: event.RegistredUsers });
      })
      .listen('.CompanyTotalPasswordsEvent', (event) => {
        this.socketCharts$.next({ key: 'password', value: event.totalPasswords });
      });
  }

  startParamsSocket() {
    this.echoInstance = this.socketService.getEchoInstance();
    this.echoInstance.private(`company.${this.socketService.companyId}.company`)
      .listen('.CompanyPasswordStrengthEvent', (event) => {
        this.socketParams$.next({ key: 'password', value: event.passwordStrength });
      })
      .listen('.CompanyPasswordFrequencyEvent', (event) => {
        this.socketParams$.next({ key: 'frequency', value: { value: event.passwordTimers, status: event.statusPasswordTimers } });
      })
      .listen('.CompanyLevelAppEvent', (event) => {
        // tslint:disable-next-line: max-line-length
        this.socketParams$.next({ key: 'level', value: event.authorizedLevels.levelGold && event.authorizedLevels.levelSilver && event.authorizedLevels.levelBronze ? 1 : event.authorizedLevels.levelSilver && event.authorizedLevels.levelGold ? 2 : 3 });
      })
      .listen('.CompanyParametreExtensionDurationEvent', (event) => {
        this.socketParams$.next({ key: 'extension', value: event.extensionDuration });
      })
      .listen('.CompanyParametreReminderDownloadEvent', (event) => {
        this.socketParams$.next({ key: 'Download', value: { value: event.reminderDownload, status: event.statusReminderDownload } });
      });
  }

  stopSocket() {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}`);
      this.listGroup = [];
      this.socketListGroup$.next(this.listGroup);
    }
  }

  stopChartsSocket() {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.charts`);
    }
  }

  stopParamsSocket() {
    if (this.echoInstance) {
      this.echoInstance.leave(`company.${this.socketService.companyId}.company`);
    }
  }

  subSocketParams(): Observable<IParamsUpdate> {
    return this.socketParams$;
  }

  subSocketCharts(): Observable<IChartsUpdate> {
    return this.socketCharts$;
  }

  subSocketListGroup(): Observable<string[]> {
    return this.socketListGroup$;
  }

  subSocketLog(): Observable<any> {
    return this.socketLog$;
  }

  setCurrentCompany(company: Company): void {
    clearTimeout(this.timeout);
    if (company && company.expiredDate) {
      const disabledAfter = new Date(company.expiredDate).getTime() - new Date().getTime();
      if (disabledAfter < 0) {
        this.timeout = setTimeout(() => {
          this.modalService.dismissAll();
          this.currentCompany$.next(company);
        }, disabledAfter);
      }
    }
    this.currentCompany$.next(company);
  }

  SubCurrentCompany(): Observable<Company> {
    return this.currentCompany$;
  }

  getCurrentCompany(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // CARD PARAMS
  getPasswordStrength(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter/password/strength')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePasswordStrength(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/password/strength', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getPasswordTimer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter/password/timer')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatePasswordTimer(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/password/timer', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getDownloadReminder(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter/download/reminder')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatetDownloadReminder(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/download/reminder', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getlevel(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter/level')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updatelevel(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/level', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getExtensionDuration(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/parameter/extension/duration')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateExtensionDuration(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/parameter/extension/duration', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // CHARTS
  getChartTotalPassword(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/total-passwords')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getChartregisteredUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/registered-users')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getChartTotalSeats(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/total-seats')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateLangConsole(lang): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.baseApiUrl + '/lang', { lang })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getGroupList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/group')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getUsersForGroup(name): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/group/users', { params: name })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLog(filters): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/logs', { params: filters })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getLogsChart(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseApiUrl + '/logs/chart')
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  validatePassword(passwordManager): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/passwordvalidation', { passwordManager })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendExportLogsRequest(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + '/logs/export', data)
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}


export interface IParamsUpdate {
  key: 'password' | 'level' | 'frequency' | 'extension' | 'backup' | 'Download';
  value: any;
}

export interface IChartsUpdate {
  key: 'seats' | 'users' | 'password';
  value: any;
}
