import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
@Injectable()
export class InputService {

  private data =
    {
      login: {
        emailLengthMin: 5,
        emailLengthMax: 50,
        passwordLengthMin: 14,
        passwordLengthMax: 60,
      },
      resetPassword: {
        passwordLengthMin: 14,
        passwordLengthMax: 60,
      },
      forget: {
        emailLengthMin: 5,
        emailLengthMax: 50,
      },
      profile: {
        firstNameLengthMin: 2,
        firstNameLengthMax: 60,
        lastNameLengthMin: 2,
        lastNameLengthMax: 60,
        phoneLengthMin: 8,
        phoneLengthMax: 60,
        indicativeLengthMin: 1,
        indicativeLengthMax: 3,
      },
      askPassword: {
        passwordLengthMin: 14,
        passwordLengthMax: 60,
      },
      askCode: {
        codeLengthMin: 6,
        codeLengthMax: 6,
      },
      user: {
        firstNameLengthMin: 2,
        firstNameLengthMax: 60,
        lastNameLengthMin: 2,
        lastNameLengthMax: 60,
        emailLengthMin: 5,
        emailLengthMax: 50,
      },
      password: {
        urlLengthMin: 2,
        urlLengthMax: 60,
        loginLengthMin: 2,
        loginLengthMax: 60,
        passwordLengthMax: 60
      },
      url: {
        urlLengthMin: 2,
        urlLengthMax: 60,
        passwordLengthMin: 1,
        passwordLengthMax: 60,
        changePasswordFrequencyMin: 1,
        changePasswordFrequencyMax: 360
      },
      passwordRules: {
        passwordRulesLengthMin: 14,
        passwordRulesLengthMax: 60,
      },
      activeDirectory: {
        hostMinLength: 3,
        hostMaxLength: 60,
        usernameMinLength: 3,
        usernameMaxLength: 60,
        passwordMinLength: 1,
        passwordMaxLength: 60,
        dnMinLength: 3,
        dndMaxLength: 90,
      },
      export: {
        nameMinLength: 3,
        nameMaxLength: 60
      },
      billing: {
        companyNameLengthMin: 3,
        companyNameLengthMax: 30
      },
      params: {
        passwordLengthMin: 14,
        passwordLengthMax: 60,
        passwordTimerMin: 1,
        passwordTimerMax: 360,
        passwordReminderMin: 1,
        passwordReminderMax: 360
      },
      groupe: {
        nameLengthMin: 1,
        nameLengthMax: 30
      }
    };

  static MatchPassword(AC: AbstractControl) {
    const Newpassword = AC.get('password').value;
    const ReNewpassword = AC.get('rePassword').value;
    if (Newpassword !== ReNewpassword) {
      AC.get('rePassword').setErrors({ MatchPassword: true });
    } else {
      AC.get('rePassword').setErrors(null);
    }
  }

  static CheckOneNotSelected(AC: AbstractControl) {
    const aclow = AC.get('excludeLowercase').value;
    const acupp = AC.get('excludeUppercase').value;
    const acnum = AC.get('excludeNumber').value;
    const acspec = AC.get('excludeSpecialCharacters').value;
    if (aclow && acupp && acnum && acspec) {
      AC.get('excludeLowercase').setErrors({ allChecked: true });
      AC.get('excludeUppercase').setErrors({ allChecked: true });
      AC.get('excludeNumber').setErrors({ allChecked: true });
      AC.get('excludeSpecialCharacters').setErrors({ allChecked: true });
    } else {
      AC.get('excludeLowercase').setErrors(null);
      AC.get('excludeUppercase').setErrors(null);
      AC.get('excludeNumber').setErrors(null);
      AC.get('excludeSpecialCharacters').setErrors(null);
    }
  }

  static noWhitespaceValidator(control: FormControl) {
    return (control.value && control.value.length) > 0 ? (control.value || '').trim().length !== 0 ? null : { whitespace: true } : null;
  }

  static noEmtyGroup(AC: AbstractControl) {
    const preloadPassword = AC.get('preloadPassword').value;
    const preloadUrl = AC.get('preloadUrl').value;
    const users = AC.get('users').value;
    if ((preloadPassword.have.length === 0 && preloadPassword.dosentHave.length === 0) &&
      (preloadUrl.have.length === 0 && preloadUrl.dosentHave.length === 0) &&
      (users.have.length === 0 && users.dosentHave.length === 0)) {
      AC.get('preloadPassword').setErrors({ empty: true });
      AC.get('preloadUrl').setErrors({ empty: true });
      AC.get('users').setErrors({ empty: true });
    } else {
      AC.get('preloadPassword').setErrors(null);
      AC.get('preloadUrl').setErrors(null);
      AC.get('users').setErrors(null);
    }
  }

  getInputConfig(key): any {
    return this.data[key];
  }
}
